import { computed } from 'vue';
import { DateTime } from 'luxon';

import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client';

export const useLatestProductPrice = (variables) => {
  const startDateTime = DateTime.fromJSDate(new Date());
  const oneYearAgo = startDateTime.minus({ year: 1 });
  const fromDate = oneYearAgo.toISODate().split('T')[0];
  const toDate = startDateTime.toISODate().split('T')[0];

  const { result, loading, onError, refetch } = useQuery(
    LATEST_PRODUCT_PRICE,
    computed(() => ({
      ...variables.value,
      aggregateBy: 'product',
      fromDate,
      toDate,
    })),
    () => ({
      enabled: variables.value.enabled,
      fetchPolicy: 'cache-first',
    })
  );

  const latestPrice = computed(
    () => result.value?.orderItemAggregationNew?.nodes[0]?.maxDateOrderItem?.netPrice ?? null
  );

  return {
    latestPrice,
    loading,
    refetch,
    onError,
  };
};

const LATEST_PRODUCT_PRICE = gql`
  query latestProductPrice($tenantId: ID, $aggregateBy: String, $fromDate: ISODate, $toDate: ISODate, $productId: ID) {
    orderItemAggregationNew(
      tenantId: $tenantId
      aggregateBy: $aggregateBy
      fromDate: $fromDate
      toDate: $toDate
      productId: $productId
    ) {
      nodes {
        maxDateOrderItem {
          netPrice
        }
      }
    }
  }
`;
