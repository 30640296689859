<template>
  <div class="trigger" :class="{ active: active }" @click="$emit('on-show-define-price-alert-modal')">
    <p v-if="active">{{ formatMoneyShekels(price) }}</p>
    <p v-else class="label">{{ $t('alert.definePriceChangeModal.noPriceToTrack') }}</p>
    <ChevronDownIcon :size="20" />
  </div>
</template>
<script>
import { ChevronDownIcon } from '@/assets/icons';

import { formatMoneyShekels } from '@/modules/purchase-management/purchaseManagementFormatters';

export default {
  name: 'DefinePriceAlertTrigger',
  components: { ChevronDownIcon },
  props: { active: { type: Boolean, default: false }, price: { type: Number, default: null } },
  emits: ['on-show-define-price-alert-modal'],
  setup() {
    return {
      formatMoneyShekels,
    };
  },
};
</script>
<style lang="scss" scoped>
.trigger {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  .label {
    color: #9295a5;
  }
  &.active {
    color: white;
    border-radius: 0.25rem;
    background: #306feb;
    padding: 0.125rem 0.375rem 0.125rem 0.125rem;
  }
  &:hover {
    cursor: pointer;
  }
}
</style>
