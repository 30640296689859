<template>
  <Modal
    :visible="true"
    :title="$t('alert.definePriceChangeModal.title')"
    :submit-text="$t('alert.definePriceChangeModal.submit')"
    :submit-loading="loadingPatchPriceChangeRule"
    width="27rem"
    :top="'17vh'"
    @on-close="onCloseModal"
    @on-submit="onSubmitModal"
  >
    <template #subtitle>
      <p>{{ product?.name }}</p>
    </template>
    <div class="define-alert-modal d-flex flex-column" :style="{ gap: '2.5rem', color: '#1F284D' }">
      <div class="d-flex flex-column gap-4">
        <div class="w-100 d-flex justify-content-between">
          <p :style="{ fontWeight: 550 }">{{ $t('alert.definePriceChangeModal.followThePriceOfThisProduct') }}</p>
          <el-switch v-model="isPriceAlertActive" :width="32" active-color="#44AA7A" inactive-color="#D2D4D7" />
        </div>
        <div
          v-if="canShowAlert"
          v-loading="loadingLatestPrice"
          class="price-alert col-sm-12"
          :class="{ disabled: !isPriceAlertActive }"
        >
          <div v-if="!alertUsed" class="alert-text col-sm-8">
            <p>
              {{ $t('alert.definePriceChangeModal.latestDocumentPrice') }}
              <b>{{ formatMoneyShekelsOrDash(latestPrice) }}</b
              >.<br />
              {{ $t('alert.definePriceChangeModal.shouldIUpdate') }}
            </p>
          </div>
          <div v-if="!alertUsed" class="alert-action col-sm-4" @click="updateAlertToLatestPrice">
            {{ $t('alert.definePriceChangeModal.updateTo', { price: formatMoneyShekelsOrDash(latestPrice) }) }}
          </div>
          <p v-if="alertUsed" class="alert-text">
            {{
              $t('alert.definePriceChangeModal.updatedLatestDocumentPrice', {
                price: formatMoneyShekelsOrDash(latestPrice),
              })
            }}
          </p>
        </div>
        <div class="d-flex w-100 justify-content-between" :class="{ disabled: !isPriceAlertActive }">
          <p>{{ $t('alert.definePriceChangeModal.priceToTrack') }}</p>
          <div class="money-input">
            <el-input
              v-model="priceToTrack"
              type="text"
              :disabled="!isPriceAlertActive"
              :style="{ alignSelf: 'flex-end' }"
              @input="onPriceInput"
            >
              <template :slot="$direction === 'rtl' ? 'append' : 'prepend'">{{ currencySymbol }}</template>
            </el-input>
            <p v-if="showPriceError && hasPriceError" :style="{ color: '#E52044' }">
              {{ $t('alert.definePriceChangeModal.priceError') }}
            </p>
            <p v-if="enteringInvalidPrice" :style="{ color: '#E52044' }">
              {{ $t('alert.definePriceChangeModal.invalidPrice') }}
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column" :class="{ disabled: !isPriceAlertActive }" :style="{ gap: '0.75rem' }">
        <p :style="{ fontWeight: 550 }">{{ $t('alert.definePriceChangeModal.trend.title') }}</p>
        <div>
          <el-radio-group v-model="trend" class="d-flex flex-column gap-2 mb-2" :disabled="!isPriceAlertActive">
            <el-radio :label="TRENDS.ANY" class="m-0">{{
              $t(`alert.definePriceChangeModal.trend.${TRENDS.ANY}`)
            }}</el-radio>
            <el-radio :label="TRENDS.DOWN" class="m-0">{{
              $t(`alert.definePriceChangeModal.trend.${TRENDS.DOWN}`)
            }}</el-radio>
            <el-radio :label="TRENDS.UP" class="m-0">{{
              $t(`alert.definePriceChangeModal.trend.${TRENDS.UP}`)
            }}</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import { isNil } from 'lodash';
import { ref, computed } from 'vue';

import i18n from '@/imports/startup/client/i18n';
import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/locale/useCurrency';
import Modal from '@/modules/catalog/components/Modal.vue';
import { useLatestProductPrice } from './compositions/useLatestProductPrice';
import { formatMoneyShekelsOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';

const TRENDS = {
  ANY: 'any',
  UP: 'up',
  DOWN: 'down',
};

export default {
  name: 'DefinePriceAlertModal',
  components: { Modal },
  props: {
    priceChangeRule: { type: Object, default: null },
    product: { type: Object, default: null },
    showAlert: { type: Boolean, default: false },
    loadingPatchPriceChangeRule: { type: Boolean, default: false },
  },
  emits: ['on-submit', 'on-close'],
  setup(props, { emit }) {
    const alertUsed = ref(false);
    const trend = ref(props.priceChangeRule?.trend ?? TRENDS.ANY);
    const priceToTrack = ref(props.priceChangeRule?.netPrice);
    const isPriceAlertActive = ref(props.priceChangeRule?.active);
    const showPriceError = ref(false);
    const enteringInvalidPrice = ref(false);
    const { currentTenant } = useTenancy();
    const { currencyFormat } = useCurrency();

    const currencySymbol = computed(() => {
      return (0).toLocaleString(i18n.locale, currencyFormat.value).replace(/\d./g, '').trim();
    });

    const { latestPrice, loading: loadingLatestPrice } = useLatestProductPrice(
      computed(() => {
        return {
          productId: props.product?.id,
          tenantId: currentTenant.value?.id,
          enabled: props.showAlert,
        };
      })
    );

    const canShowAlert = computed(() => {
      if (!props.showAlert) {
        return false;
      }
      if (props.priceChangeRule?.netPrice === latestPrice.value || !latestPrice.value) {
        return false;
      }
      return true;
    });

    const hasPriceError = computed(() => {
      return isNil(priceToTrack.value) || priceToTrack.value === '';
    });

    const onCloseModal = () => {
      emit('on-close');
    };

    const onSubmitModal = () => {
      const hasChangeInPrice = priceToTrack.value !== props.priceChangeRule?.netPrice;
      const priceChangeRuleExists = props.priceChangeRule !== null;
      const hasChangeInTrend = priceChangeRuleExists ? trend.value !== props.priceChangeRule?.trend : false;

      let hasChangeInActive = false;
      if (priceChangeRuleExists) {
        hasChangeInActive = isPriceAlertActive.value !== props.priceChangeRule?.active;
      } else {
        hasChangeInActive = isPriceAlertActive.value === true;
      }
      const hasAnyChange = hasChangeInPrice || hasChangeInTrend || hasChangeInActive;

      if (!hasAnyChange) {
        emit('on-close');
        return;
      }
      if (hasPriceError.value) {
        showPriceError.value = true;
        return;
      }
      emit('on-submit', { netPrice: Number(priceToTrack.value), trend: trend.value, active: isPriceAlertActive.value });
    };

    const updateAlertToLatestPrice = () => {
      if (isPriceAlertActive.value) {
        alertUsed.value = true;
        priceToTrack.value = latestPrice.value;
      }
    };

    const onPriceInput = (value) => {
      const invalidChar = /[^0-9.,]/.test(value);

      if (invalidChar) {
        priceToTrack.value = value.replace(/[^0-9.,]/g, '');
        enteringInvalidPrice.value = true;
      } else {
        enteringInvalidPrice.value = false;
      }
    };
    return {
      TRENDS,
      trend,
      alertUsed,
      latestPrice,
      canShowAlert,
      priceToTrack,
      hasPriceError,
      showPriceError,
      currencySymbol,
      loadingLatestPrice,
      isPriceAlertActive,
      enteringInvalidPrice,
      onPriceInput,
      onCloseModal,
      onSubmitModal,
      formatMoneyShekelsOrDash,
      updateAlertToLatestPrice,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.price-alert {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  gap: 0.5rem;
  align-items: flex-end;
  border-radius: 0.5rem;
  background: #ecf2fd;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;

  .alert-text {
    word-break: normal;
  }

  .alert-action {
    cursor: pointer;
    width: fit-content;
    color: #0d50d4;
    text-decoration: underline;
  }
}
.disabled {
  .alert-action {
    cursor: not-allowed !important;
    color: #0d50d4;
  }
}
.disabled {
  opacity: 0.5;
}
::v-deep {
  div.money-input {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
    .el-input__inner {
      transition: border-color 0s ease;
      direction: ltr;
      height: 2rem;
      padding: 0 5px;
    }
    .el-input-group--append {
      width: 6rem;
      .el-input__inner {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .el-input-group__append {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      padding-left: 0.5rem;
      padding-right: 0.3rem;
      color: $typography-primary;
      vertical-align: middle;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .el-input-group--prepend {
      width: 6rem;
    }
    .el-input-group__prepend {
      color: $typography-primary;
      padding-left: 0.5rem;
      padding-right: 0.3rem;
    }
  }
}
</style>
