import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

export function useAlerts(variables, options) {
  const { error } = useNotification();

  const { result, loading, onError, refetch, onResult } = useQuery(ALERTS_QUERY, variables, options);

  const alertConnection = computed(() => (result.value ? result.value.alerts : { nodes: [], totalCount: 0 }));

  const totalCount = computed(() => alertConnection.value.totalCount);

  const alerts = computed(() => alertConnection.value.nodes);

  onError((err) => {
    console.error('useAlerts', err);
    error();
  });

  return {
    alerts,
    refetch,
    loading,
    totalCount,
    onResult,
  };
}

export const ALERTS_QUERY = gql`
  query getAlerts($businessId: ID!, $handled: Boolean, $type: String, $first: Int, $after: Int) {
    alerts(businessId: $businessId, handled: $handled, type: $type, first: $first, after: $after) {
      nodes {
        id
        businessId
        type
        data
        handled
        handledBy
        handledAt
        createdAt
        handledByUser {
          profile {
            firstName
            lastName
            profilePicture
          }
        }
      }
      totalCount
    }
  }
`;

export function useCurrentTenantTotalUnhandledAlerts(alertEnabled) {
  const { currentTenant } = useTenancy();
  const { error } = useNotification();

  const { result, loading, refetch, onError } = useQuery(
    ALERTS_TOTAL_UNHANDLED_QUERY,
    computed(() => ({
      businessId: currentTenant.value.id,
    })),
    () => ({
      fetchPolicy: 'no-cache',
      enabled: alertEnabled?.value,
    })
  );

  const alertConnection = computed(() => (result.value ? result.value.alerts : { totalCount: 0 }));

  const totalCount = computed(() => alertConnection.value.totalCount);

  onError((err) => {
    console.error('useAlerts', err);
    error();
  });

  return {
    loading,
    totalCount,
    refetch,
  };
}

export const ALERTS_TOTAL_UNHANDLED_QUERY = gql`
  query getTotalUnhandledAlerts($businessId: ID!, $type: String) {
    alerts(businessId: $businessId, type: $type, handled: false) {
      totalCount
    }
  }
`;
