<template>
  <div v-loading="loadingData" class="price-change-alert-details">
    <div class="d-flex flex-column gap-2">
      <div>
        <p :style="{ fontWeight: 550 }">{{ $t('alert.types.product_price_change.drawer.content.details') }}</p>
      </div>
      <div v-for="detail in details" :key="`detail${detail.label}`" class="detail">
        <div v-if="detail.tooltipText" class="d-flex gap-1 label align-items-center">
          <p>{{ detail.label }}</p>
          <el-tooltip
            placement="top"
            popper-class="annualImpact-tooltip"
            :content="$t('alert.types.product_price_change.drawer.content.annualImpactTooltip')"
          >
            <QuestionMarkFullIcon :size="16" />
          </el-tooltip>
        </div>
        <p v-else class="label">{{ detail.label }}</p>
        <div
          class="value"
          :class="{ actionable: detail.actionable, [detail.customClass]: detail.customClass }"
          @click="() => (detail.actionable ? detail.action() : () => {})"
        >
          <TruncatedText>{{ detail.value }}</TruncatedText>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column gap-2 h-100">
      <p class="document-link" @click="documentModalVisible = true">
        {{
          $t('alert.types.product_price_change.drawer.content.documentNumber', {
            documentNumber: document?.documentNumber,
          })
        }}
      </p>
      <FileViewer v-if="document?.filePathUrl" :url="document?.filePathUrl" />
    </div>
    <DocumentModal
      v-if="documentModalVisible"
      visible
      :document-id="document?.id"
      @close="documentModalVisible = false"
    />
  </div>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { FileViewer } from '@clarityo/ui-components';

import { TruncatedText } from '@/modules/core';
import { QuestionMarkFullIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { openProductModal } from '@/modules/products/store/product-modal';
import { useOrderForPriceChangeAlert } from './compositions/useOrderForPriceChangeAlert';
import { formatDateOrDash, formatMoneyShekelsOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';

export default {
  name: 'ProductPriceChangeAlertDetails',
  components: { QuestionMarkFullIcon, FileViewer, DocumentModal, TruncatedText },
  props: {
    alert: { type: Object, default: null },
    supplierName: { type: String, default: null },
    orderItemAggregation: { type: Object, default: null },
  },
  emits: [''],
  setup(props) {
    const root = getCurrentInstance().proxy;
    const documentModalVisible = ref(false);
    const product = computed(() => props.alert?.data?.product);
    const orderId = computed(() => props.alert?.data?.orderId);

    const { order, loading: orderLoading } = useOrderForPriceChangeAlert(orderId);

    const loadingData = computed(() => {
      return orderLoading.value;
    });

    const document = computed(() => order.value?.eventReferences[0]?.document);

    const triggerProductModal = () => {
      openProductModal(product.value?.id);
    };

    const details = computed(() => {
      const difference = props.alert?.data?.netPrice - props.alert?.data?.rulePrice;
      const orderItem = order.value?.products?.find((item) => item.productId === product.value?.id);
      const quantity = orderItem?.quantity;

      const annualImpact = props.orderItemAggregation?.monthlyAverageTotalQuantity * 12 * difference;

      const detailsToReturn = [
        {
          label: root.$t('alert.types.product_price_change.drawer.content.product'),
          value: product.value?.name,
          actionable: true,
          action: triggerProductModal,
        },
        {
          label: root.$t('alert.types.product_price_change.drawer.content.sku'),
          value: product.value?.sku,
          actionable: true,
          action: triggerProductModal,
        },
        {
          label: root.$t('alert.types.product_price_change.drawer.content.supplier'),
          value: props.supplierName,
          actionable: false,
        },
        {
          label: root.$t('alert.types.product_price_change.drawer.content.dateOfAlert'),
          value: formatDateOrDash(props.alert?.createdAt),
          actionable: false,
        },
        {
          label: root.$t('alert.types.product_price_change.drawer.content.priceInDocument'),
          value: formatMoneyShekelsOrDash(props.alert?.data?.netPrice),
          actionable: false,
        },
        {
          label: root.$t('alert.types.product_price_change.drawer.content.priceInTracking'),
          value: formatMoneyShekelsOrDash(props.alert?.data?.rulePrice),
          actionable: false,
        },
        {
          label: root.$t('alert.types.product_price_change.drawer.content.difference'),
          value: formatMoneyShekelsOrDash(difference * quantity),
          actionable: false,
        },
        {
          label: root.$t('alert.types.product_price_change.drawer.content.annualImpact'),
          tooltipText: root.$t('alert.types.product_price_change.drawer.content.annualImpactTooltip'),
          value: formatMoneyShekelsOrDash(annualImpact),
          customClass: annualImpact <= 0 ? 'up' : 'down',
          actionable: false,
        },
      ];
      return detailsToReturn;
    });

    return { details, product, document, loadingData, documentModalVisible };
  },
};
</script>
<style lang="scss" scoped>
.price-change-alert-details {
  height: 100%;
  padding: 0 1.5rem !important;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.detail {
  display: flex;
  width: 100%;
  gap: 2rem;
  height: 21px;

  .label {
    width: 40%;
  }
  .value {
    width: 50%;
    color: #9295a5;
  }
  .up {
    color: #11874f !important;
  }
  .down {
    color: #e52044 !important;
  }
}
.actionable {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.direction-ltr {
  margin-inline-start: 2.2rem;
  direction: ltr;
}
.document-link {
  color: #0d50d4;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
<style lang="scss">
.annualImpact-tooltip {
  &.el-tooltip__popper {
    max-width: 14rem;
    padding: 5px;
  }
}
</style>
