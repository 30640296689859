import i18n from '@/imports/startup/client/i18n';
import moment from 'moment';

export const ALERTS_HEADERS = {
  CREATED_AT: 'createdAt',
  TYPE: 'type',
  DETAILS: 'details',
  CHAT: 'chat',
};

export const getTranslation = (key) => i18n.t(`alert.alertsPage.table.headers.${key}`);

export const getAlertsTableColumns = () => [
  {
    header: getTranslation(ALERTS_HEADERS.CREATED_AT),
    key: ALERTS_HEADERS.CREATED_AT,
    width: '3rem',
  },
  {
    header: getTranslation(ALERTS_HEADERS.TYPE),
    key: ALERTS_HEADERS.TYPE,
    width: '115px',
  },
  {
    header: getTranslation(ALERTS_HEADERS.DETAILS),
    key: ALERTS_HEADERS.DETAILS,
    width: '320px',
  },
  // {
  //   header: '',
  //   key: ALERTS_HEADERS.CHAT,
  //   width: '76px',
  // },
];

export const AlertType = {
  PRODUCT_PRICE_CHANGE: 'PRODUCT_PRICE_CHANGE',
};

export const getAlertTypeTranslation = (type) => (type ? i18n.t(`alert.types.${type.toLowerCase()}.label`) : '');

export const getAlertTypeIcon = (type) => {
  switch (type?.toUpperCase()) {
    case AlertType.PRODUCT_PRICE_CHANGE:
      return 'ProductPriceChangeIcon';
    default:
      return '';
  }
};

export const getAlertCreatedAtFormatted = (createdAt) => {
  const date = moment(createdAt);
  const locale = i18n.locale;

  if (date.isSame(moment(), 'day')) {
    return i18n.t('alert.alertsPage.table.columns.createdAt.today');
  } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
    return i18n.t('alert.alertsPage.table.columns.createdAt.yesterday');
  } else {
    return date.locale(locale).format('D.M.YY');
  }
};
