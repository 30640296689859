<template>
  <div class="w-100">
    <TruncatedText v-if="alert?.type.toUpperCase() === AlertType.PRODUCT_PRICE_CHANGE" :text-no-wrap="false">
      {{
        $t(
          `alert.types.${AlertType.PRODUCT_PRICE_CHANGE.toLowerCase()}.details.${
            valuesByAlertType?.isUpTrend ? 'up' : 'down'
          }`,
          {
            productName: valuesByAlertType?.productName,
            priceDifference: valuesByAlertType?.priceDifference,
          }
        )
      }}
      {{ $t(`alert.types.${AlertType.PRODUCT_PRICE_CHANGE.toLowerCase()}.details.annualImpact`) }}
      <span :style="{ color: valuesByAlertType?.annualImpact <= 0 ? '#11874F' : '#E52044' }">{{
        formatMoneyShekels(valuesByAlertType?.annualImpact)
      }}</span>
      {{ $t(`alert.types.${AlertType.PRODUCT_PRICE_CHANGE.toLowerCase()}.details.inAYear`) }}
    </TruncatedText>
  </div>
</template>
<script type="text/javascript">
import { computed, getCurrentInstance } from 'vue';

import { TruncatedText } from '@/modules/core';
import { AlertType } from '../alerts-helpers';
import { formatMoneyShekels } from '@/modules/purchase-management/purchaseManagementFormatters';

export default {
  name: 'AlertDetailsColumn',
  components: { TruncatedText },
  props: { alert: { type: Object, required: true }, orderItemAggregation: { type: Array, default: null } },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const valuesByAlertType = computed(() => {
      const { type } = props.alert;

      switch (type?.toUpperCase()) {
        case AlertType.PRODUCT_PRICE_CHANGE:
          return getProductPriceChangeValues(props.alert);

        default:
          return {};
      }
    });
    const getProductPriceChangeValues = (alert) => {
      const { data } = alert;
      const { netPrice, rulePrice, product } = data;

      const isUpTrend = netPrice > rulePrice;

      const difference = netPrice - rulePrice;
      const priceDifference = formatMoneyShekels(Math.abs(difference));
      const relevantOrderItemAggregation = props.orderItemAggregation?.find((item) => item.productId === product.id);
      const annualImpact = relevantOrderItemAggregation?.monthlyAverageTotalQuantity * 12 * difference;

      return {
        isUpTrend,
        priceDifference,
        annualImpact,
        productName: product.name,
      };
    };
    const textByTypeAndValue = computed(() => {
      const { type } = props.alert;

      const getProductPriceChangeText = (alert) => {
        const { data } = alert;
        const { netPrice, rulePrice, product } = data;

        const isUpTrend = netPrice > rulePrice;

        const difference = netPrice - rulePrice;
        const priceDifference = formatMoneyShekels(difference);
        const relevantOrderItemAggregation = props.orderItemAggregation?.find((item) => item.productId === product.id);
        const annualImpact = relevantOrderItemAggregation?.monthlyAverageTotalQuantity * difference;

        const firstHalf = root.$t(
          `alert.types.${AlertType.PRODUCT_PRICE_CHANGE.toLowerCase()}.details.${isUpTrend ? 'up' : 'down'}`,
          {
            productName: product.name,
            priceDifference,
          }
        );

        const secondHalf = `${root.$t(
          `alert.types.${AlertType.PRODUCT_PRICE_CHANGE.toLowerCase()}.details.annualImpact`
        )} ${formatMoneyShekels(annualImpact)} ${root.$t(
          `alert.types.${AlertType.PRODUCT_PRICE_CHANGE.toLowerCase()}.details.inAYear`
        )}`;

        return `${firstHalf} ${secondHalf}`;
      };

      switch (type?.toUpperCase()) {
        case AlertType.PRODUCT_PRICE_CHANGE:
          return getProductPriceChangeText(props.alert);

        default:
          return '';
      }
    });

    return {
      AlertType,
      valuesByAlertType,
      textByTypeAndValue,
      formatMoneyShekels,
    };
  },
};
</script>
