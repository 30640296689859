<template>
  <div>
    <PageLayout class="container">
      <template #header>
        <h1>
          {{ $t('alert.alertsPage.title') }}
        </h1>
      </template>

      <div class="mb-6">
        <Tabs :tabs="tabs" :active-tab.sync="activeTab" @click="onActiveTabChanged" />
      </div>

      <AlertsTable
        v-if="alerts.length || isLoading"
        :alerts="alerts"
        :limit="limit"
        :page="page"
        :loading="isLoading"
        :total-count="totalCount"
        :selected-index="selectedIndex"
        :order-item-aggregation="orderItemAggregation"
        @on-row-click="handleRowClick"
      />
      <EmptyAlerts v-else :is-handled="!isUnhandledTab" />

      <div class="d-flex justify-content-end my-3">
        <el-pagination
          v-if="PAGE_LIMIT < totalCount"
          small
          layout="prev, pager, next, jumper"
          background
          :current-page.sync="page"
          :page-size="PAGE_LIMIT"
          :page-count="Math.ceil(totalCount / PAGE_LIMIT)"
          :total="totalCount"
        />
      </div>
    </PageLayout>

    <AlertsDrawer
      :is-visible="!!(drawerVisible && selectedAlert)"
      :title="selectedAlert ? $t(`alert.types.${selectedAlert?.type}.label`) : ''"
      :alert="selectedAlert"
      @close-drawer="closeDrawer"
      @on-drawer-closed="handleDrawerClosed"
    >
      <component
        :is="currentAlertComponent"
        :alert="selectedAlert"
        :order-item-aggregation="relevantOrderItemAggregation"
        @on-handled-status-change="handleStatusChange"
      />
    </AlertsDrawer>
  </div>
</template>

<script type="text/javascript">
import { PageLayout, Tabs } from '@/modules/core';
import { computed, getCurrentInstance, ref } from 'vue';
import AlertsTable from './components/AlertsTable/AlertsTable.vue';
import { useAlerts, useCurrentTenantTotalUnhandledAlerts } from './compositions/useAlerts';
import { useTenancy } from '../auth';
import AlertsDrawer from '../purchase-management/components/alertsDrawer/AlertsDrawer.vue';
import { useUpdateAlert } from './compositions/useUpdateAlert';
import { useAnnualImpactForPriceChangeAlert } from './components/productPriceChangeAlert/compositions/useAnnualImpactForPriceChangeAlert';
import EmptyAlerts from '../purchase-management/components/alertsDrawer/EmptyAlerts.vue';
import ProductPriceChangeAlert from './components/productPriceChangeAlert/ProductPriceChangeAlert.vue';

const PAGE_LIMIT = 15;

const alertComponentMap = {
  product_price_change: ProductPriceChangeAlert,
};

export default {
  components: { PageLayout, Tabs, AlertsTable, AlertsDrawer, EmptyAlerts },
  setup() {
    const root = getCurrentInstance().proxy;
    const activeTab = ref(0);
    const page = ref(1);
    const limit = ref(PAGE_LIMIT);
    const { currentTenant } = useTenancy();
    const drawerVisible = ref(false);
    const selectedIndex = ref(-1);
    const { updateAlert } = useUpdateAlert();

    const onActiveTabChanged = () => {
      page.value = 1;
    };

    const isUnhandledTab = computed(() => activeTab.value === 0);

    const { alerts, loading, totalCount, refetch } = useAlerts(
      computed(() => ({
        businessId: currentTenant.value.id,
        handled: !isUnhandledTab.value,
      }))
    );

    const { orderItemAggregation, loading: orderItemAggregationLoading } = useAnnualImpactForPriceChangeAlert(
      computed(() => {
        const productIds = alerts.value.map(({ data }) => data?.product?.id);

        return {
          tenantId: currentTenant.value?.id,
          productIds,
        };
      })
    );

    const {
      totalCount: totalUnhandledAlerts,
      loading: totalUnhandledAlertsLoading,
      refetch: refetchTotalUnhandledAlerts,
    } = useCurrentTenantTotalUnhandledAlerts();

    const isLoading = computed(
      () => loading.value || totalUnhandledAlertsLoading.value || orderItemAggregationLoading.value
    );

    const tabs = computed(() => {
      const badge = totalUnhandledAlerts.value ? { badgeValue: totalUnhandledAlerts.value } : {};
      return [
        { text: root.$t('alert.alertsPage.tabs.unhandled'), ...badge },
        { text: root.$t('alert.alertsPage.tabs.handled') },
      ];
    });

    const closeDrawer = () => {
      drawerVisible.value = false;
    };

    const openDrawer = () => {
      drawerVisible.value = true;
    };

    const handleRowClick = (index) => {
      selectedIndex.value = index;
      openDrawer();
    };

    const selectedAlert = computed(() => alerts.value[selectedIndex.value]);
    const relevantOrderItemAggregation = computed(() =>
      orderItemAggregation.value?.find((item) => item.productId === selectedAlert.value?.data?.product?.id)
    );

    const currentAlertComponent = computed(() => {
      const { type } = selectedAlert.value || {};
      return alertComponentMap[type];
    });

    const updateTotalUnhandledAlerts = async () => {
      refetchTotalUnhandledAlerts();

      const refetchAlertsInNavigationMenuEvent = new CustomEvent('alert-updated');
      window.dispatchEvent(refetchAlertsInNavigationMenuEvent);
    };

    const handleStatusChange = async (handled) => {
      await updateAlert({
        updateAlertId: selectedAlert.value.id,
        params: { handled },
      });
      if (handled) {
        root.$message.success(root.$t('alert.alertsPage.updatedHandled'));
      } else {
        root.$message.success(root.$t('alert.alertsPage.updatedUnhandled'));
      }
      refetch();
      updateTotalUnhandledAlerts();
      selectedIndex.value = -1;
    };

    const handleDrawerClosed = () => {
      selectedIndex.value = -1;
    };

    return {
      activeTab,
      onActiveTabChanged,
      tabs,
      limit,
      page,
      alerts,
      isLoading,
      totalCount,
      selectedIndex,
      orderItemAggregation,
      relevantOrderItemAggregation,
      currentAlertComponent,
      closeDrawer,
      openDrawer,
      handleRowClick,
      drawerVisible,
      selectedAlert,
      handleStatusChange,
      isUnhandledTab,
      handleDrawerClosed,
      PAGE_LIMIT,
    };
  },
};
</script>
