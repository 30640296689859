<template>
  <div>
    <TableLoadingSkeleton v-if="loading" />

    <Table
      v-else
      border
      rounded
      :data="alerts"
      :columns="columns"
      :cell-class="handleCellClass"
      @row-click="onRowClick"
    >
      <template #[`cell-${ALERTS_HEADERS.CREATED_AT}`]="{ rowData: { createdAt } }">
        {{ getAlertCreatedAtFormatted(createdAt) }}
      </template>

      <template #[`cell-${ALERTS_HEADERS.TYPE}`]="{ rowData: { type } }">
        <div class="alert-type-column">
          <component :is="getAlertTypeIcon(type)" class="alert-type-icon" />

          {{ getAlertTypeTranslation(type) }}
        </div>
      </template>

      <template #[`cell-${ALERTS_HEADERS.DETAILS}`]="{ rowData }">
        <AlertDetailsColumn :alert="rowData" :order-item-aggregation="orderItemAggregation" />
      </template>
    </Table>
  </div>
</template>
<script type="text/javascript">
import { Table, TableLoadingSkeleton } from '@/modules/core';
import {
  ALERTS_HEADERS,
  getAlertCreatedAtFormatted,
  getAlertsTableColumns,
  getAlertTypeIcon,
  getAlertTypeTranslation,
} from '../alerts-helpers';
import { computed } from 'vue';
import { ProductPriceChangeIcon } from '@/assets/icons';
import AlertDetailsColumn from './AlertDetailsColumn.vue';

export default {
  components: { Table, TableLoadingSkeleton, ProductPriceChangeIcon, AlertDetailsColumn },
  props: {
    limit: { type: Number, required: true },
    page: { type: Number, required: true },
    alerts: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    totalCount: { type: Number, required: true },
    selectedIndex: { type: Number, required: true },
    orderItemAggregation: { type: Array, default: null },
  },
  setup(props, { emit }) {
    const columns = getAlertsTableColumns();
    const currentIndex = computed(() => (props.page - 1) * props.limit + 1);

    const handleCellClass = (rowIndex) => {
      if (rowIndex === props.selectedIndex) {
        return 'active-row';
      }
    };

    const onRowClick = (index) => {
      emit('on-row-click', index);
    };

    return {
      columns,
      currentIndex,
      ALERTS_HEADERS,
      onRowClick,
      handleCellClass,
      getAlertTypeIcon,
      getAlertTypeTranslation,
      getAlertCreatedAtFormatted,
    };
  },
};
</script>
<style lang="scss" scoped>
.alert-type-column {
  [dir='rtl'] & {
    display: flex;

    .alert-type-icon {
      margin-left: 8px;
    }
  }
  [dir='ltr'] & {
    display: flex;
    flex-direction: row-reverse;
    .alert-type-icon {
      margin-right: 8px;
    }
  }
}
::v-deep {
  .active-row {
    background-color: #f8fafb;
  }
}
</style>
