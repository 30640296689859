import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export const useUpdateAlert = () => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(UPDATE_ALERT);

  onError((err) => {
    console.error('useUpdateAlert', err);
    error();
  });

  return {
    updateAlert: mutate,
    loading,
    onDone,
  };
};
const UPDATE_ALERT = gql`
  mutation UpdateAlert($updateAlertId: ID!, $params: UpdateAlertInput!) {
    updateAlert(id: $updateAlertId, params: $params) {
      id
      businessId
      type
      data
      handled
      handledBy
      handledAt
      handledByUser {
        id
        profile {
          firstName
          lastName
          profilePicture
        }
      }
      createdAt
    }
  }
`;
