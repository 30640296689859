import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function usePurchaseListStatusForProduct(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(PURCHASE_LIST_ITEMS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));

  const purchaseListItem = computed(() => {
    if (result.value) {
      return result.value.purchaseListItem;
    }

    return null;
  });

  onError((err) => {
    console.error('usePurchaseListStatusForProduct', err);
    error();
  });

  const purchaseListItemExists = computed(() => !!purchaseListItem.value);

  return {
    loading,
    purchaseListItem,
    purchaseListItemExists,
    refetch,
  };
}

export const PURCHASE_LIST_ITEMS_QUERY = gql`
  query purchaseListItemForProduct($businessId: ID!, $productId: ID!) {
    purchaseListItem(businessId: $businessId, productId: $productId) {
      isRecommended
      supplier {
        name
      }
    }
  }
`;
