<template>
  <div v-loading="loadingData" class="h-100">
    <AlertQuickActions :alert="alert" :header-actions="headerActions" @on-handled-status-change="onHandledStatusChange">
      <template #quick-actions>
        <div class="quick-action">
          <div class="label">
            <PriceIndexIcon class="icon" :size="16" />
            <span>{{ $t('alert.types.product_price_change.drawer.quickActions.priceInTracking') }}</span>
          </div>
          <div class="action">
            <DefinePriceAlertTrigger
              :price="priceChangeRule?.netPrice"
              :active="priceChangeRule?.active"
              @on-show-define-price-alert-modal="showDefinePriceAlertModal = true"
            />
          </div>
        </div>
        <div class="quick-action">
          <div class="label">
            <PurchaseListIcon class="icon" :size="16" />
            <span>{{ $t('alert.types.product_price_change.drawer.quickActions.purchaseList') }}</span>
          </div>
          <div class="action">
            <PurchaseListProductUpdateDropdown
              :purchase-list-item="purchaseListItem"
              @on-purchase-list-change="onPurchaseListChange"
            />
          </div>
        </div>
      </template>
    </AlertQuickActions>
    <ProductPriceChangeAlertDetails
      :alert="alert"
      :supplier-name="supplierName"
      :order-item-aggregation="orderItemAggregation"
    />
    <DefinePriceAlertModal
      v-if="showDefinePriceAlertModal"
      :product="productAlerted"
      :price-change-rule="priceChangeRule"
      :loading-patch-price-change-rule="loadingPatchPriceChangeRule"
      @on-close="showDefinePriceAlertModal = false"
      @on-submit="onSubmitDefinePriceAlert"
    />
  </div>
</template>
<script>
import { ref, computed, getCurrentInstance, watch } from 'vue';

import { ProductIcon, PriceIndexIcon, PurchaseListIcon } from '@/assets/icons';

import { useTenancy } from '@/modules/auth';
import { openProductModal, productId as productModalId } from '@/modules/products/store/product-modal';

import ProductPriceChangeAlertDetails from './ProductPriceChangeAlertDetails.vue';
import AlertQuickActions from '@/modules/alert/components/AlertQuickActions.vue';
import PurchaseListProductUpdateDropdown from './PurchaseListProductUpdateDropdown.vue';
import DefinePriceAlertModal from './DefinePriceAlertModal.vue';
import DefinePriceAlertTrigger from './DefinePriceAlertTrigger.vue';
import { usePriceChangeRule } from './compositions/usePriceChangeRule';
import { usePurchaseListStatusForProduct } from './compositions/usePurchaseListStatusForProduct';
import { useUpdatePurchaseListItem } from '../../../purchase-management/compositions/purchaseList/useUpdatePurchaseListItem';
import { usePatchPriceChangeRule } from './compositions/usePatchPriceChangeRule';

export default {
  name: 'ProductPriceChangeAlert',
  components: {
    PriceIndexIcon,
    PurchaseListIcon,
    AlertQuickActions,
    DefinePriceAlertModal,
    DefinePriceAlertTrigger,
    ProductPriceChangeAlertDetails,
    PurchaseListProductUpdateDropdown,
  },
  props: { alert: { type: Object, required: true }, orderItemAggregation: { type: Object, default: null } },
  emits: ['on-handled-status-change'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const showDefinePriceAlertModal = ref(false);
    const productAlerted = computed(() => props.alert?.data?.product);
    const hasPurchaseListItem = ref(false);
    const openedProductModal = ref(false);

    const headerActions = [
      {
        label: root.$t('alert.types.product_price_change.drawer.quickActions.productDetails'),
        icon: ProductIcon,
        action: () => openProductModal(productAlerted.value?.id),
      },
    ];

    const { currentTenant } = useTenancy();
    const {
      patchPriceChangeRule,
      loading: loadingPatchPriceChangeRule,
      onDone: onPatchPriceChangeRuleDone,
    } = usePatchPriceChangeRule();
    const { updatePurchaseListItem } = useUpdatePurchaseListItem();
    const {
      purchaseListItem,
      loading: purchaseListItemLoading,
      refetch: refetchPurchaseListStatus,
    } = usePurchaseListStatusForProduct(
      computed(() => ({ productId: productAlerted.value?.id, businessId: currentTenant.value?.id }))
    );
    const {
      priceChangeRule,
      loading: priceChangeRuleLoading,
      refetch,
    } = usePriceChangeRule(
      computed(() => ({
        businessId: currentTenant.value?.id,
        productId: productAlerted.value?.id,
      }))
    );
    const loadingData = computed(() => {
      return purchaseListItemLoading.value || priceChangeRuleLoading.value;
    });

    const supplierName = computed(() => purchaseListItem.value?.supplier?.name);

    const onSubmitDefinePriceAlert = (patchPayload) => {
      if (priceChangeRule.value) {
        patchPriceChangeRule({
          priceChangeRulePatchId: priceChangeRule.value.id,
          ...patchPayload,
        });
      }
    };
    onPatchPriceChangeRuleDone(() => {
      refetch();
      showDefinePriceAlertModal.value = false;
      root.$message.success(root.$t('alert.definePriceChangeModal.successMessage'));
    });
    const onHandledStatusChange = (payload) => emit('on-handled-status-change', payload);
    const onPurchaseListChange = (isRecommended) => {
      if (!hasPurchaseListItem.value && !purchaseListItem.value && isRecommended === null) {
        return;
      }
      hasPurchaseListItem.value = true;
      updatePurchaseListItem({
        businessId: currentTenant.value?.id,
        productId: productAlerted.value?.id,
        supplierId: productAlerted.value?.businessId,
        isRecommended,
      });
    };

    watch(
      productModalId,
      () => {
        if (!productModalId.value && openedProductModal.value) {
          refetch();
          refetchPurchaseListStatus();
        } else if (!openedProductModal.value) {
          openedProductModal.value = true;
        }
      },
      { immediate: true, deep: true }
    );

    return {
      loadingData,
      supplierName,
      headerActions,
      productAlerted,
      priceChangeRule,
      purchaseListItem,
      showDefinePriceAlertModal,
      loadingPatchPriceChangeRule,
      onPurchaseListChange,
      onHandledStatusChange,
      onSubmitDefinePriceAlert,
    };
  },
};
</script>
<style lang="scss" scoped>
.quick-action {
  display: flex;
  width: 100%;
  font-size: 0.875rem;

  .label {
    display: flex;
    width: 46%;
    align-items: center;

    .icon {
      margin-inline-end: 10px;
    }
  }

  .action {
    width: 50%;
  }
}
</style>
