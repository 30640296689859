import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core/compositions/notification';

export const usePatchPriceChangeRule = () => {
  const { error } = useNotification();

  const { mutate, onError, onDone, loading } = useMutation(PATCH_PRICE_CHANGE_RULE);

  onError((err) => {
    error();
    console.error('usePatchPriceChangeRule', err);
  });

  return {
    patchPriceChangeRule: mutate,
    onError,
    onDone,
    loading,
  };
};

const PATCH_PRICE_CHANGE_RULE = gql`
  mutation PriceChangeRulePatch($priceChangeRulePatchId: ID!, $netPrice: Float, $trend: String, $active: Boolean) {
    priceChangeRulePatch(id: $priceChangeRulePatchId, netPrice: $netPrice, trend: $trend, active: $active) {
      businessId
      productId
      netPrice
      trend
      id
      active
      createdAt
    }
  }
`;
