<template>
  <el-dropdown :value="purchaseListSelectedOption?.value" trigger="click" @command="onPurchaseListChange">
    <div class="d-flex align-items-center gap-1">
      <div class="d-flex align-items-center gap-1">
        <component :is="purchaseListSelectedOption.icon" :size="16" :class="purchaseListSelectedOption.value" />
        <span class="purchase-list-value primary">{{ purchaseListSelectedOption.label }}</span>
      </div>
      <ChevronDownIcon :size="20" />
    </div>
    <el-dropdown-menu>
      <el-dropdown-item v-for="option in purchaseListOptions" :key="option.value" :command="option.value">
        <div
          class="d-flex align-items-center gap-2"
          :class="{ active: purchaseListSelectedOption?.value === option.value }"
        >
          <component :is="option.icon" :size="16" :class="option.value" />
          <span class="primary">{{ option.label }}</span>
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import { ref, computed } from 'vue';

import { DisabledIcon, ListCheckIcon, NeutralIcon, ChevronDownIcon } from '@/assets/icons';

import {
  PURCHASE_LIST_FILTER_OPTIONS,
  getPurchaseListFilterOptions,
} from '../../../purchase-management/components/supplierSettings/suppliersSettingsColumns';

export default {
  name: 'PurchaseListProductUpdateDropdown',
  components: { DisabledIcon, ListCheckIcon, NeutralIcon, ChevronDownIcon },
  props: { purchaseListItem: { type: Object, default: null } },
  emits: ['on-purchase-list-change'],
  setup(props, { emit }) {
    const isRecommendedState = ref(null);
    const purchaseListOptions = getPurchaseListFilterOptions();

    const getPurchaseListOptionByIsRecommended = (isRecommended) => {
      if (isRecommended === null) {
        return PURCHASE_LIST_FILTER_OPTIONS.NEUTRAL;
      }
      if (isRecommended) {
        return PURCHASE_LIST_FILTER_OPTIONS.APPROVED;
      }
      return PURCHASE_LIST_FILTER_OPTIONS.DISABLED;
    };

    const getIsRecommendedByPurchaseListOption = (purchaseListOption) => {
      if (purchaseListOption === PURCHASE_LIST_FILTER_OPTIONS.APPROVED) {
        return true;
      }
      if (purchaseListOption === PURCHASE_LIST_FILTER_OPTIONS.DISABLED) {
        return false;
      }

      return null;
    };

    const isRecommended = computed(() => {
      if (isRecommendedState.value) {
        return isRecommendedState.value.isRecommended;
      }
      return props.purchaseListItem ? props.purchaseListItem.isRecommended : null;
    });

    const purchaseListSelectedOption = computed(() => {
      const optionToFind = getPurchaseListOptionByIsRecommended(isRecommended.value);
      return purchaseListOptions.find((option) => option.value === optionToFind);
    });

    const onPurchaseListChange = (choice) => {
      const isRecommendedValue = getIsRecommendedByPurchaseListOption(choice);
      isRecommendedState.value = { isRecommended: isRecommendedValue };
      emit('on-purchase-list-change', isRecommendedValue);
    };

    return {
      purchaseListOptions,
      purchaseListSelectedOption,
      onPurchaseListChange,
    };
  },
};
</script>
<style lang="scss" scoped>
.primary {
  color: #1f284d;
}
div.active {
  font-weight: 700;
  .primary {
    color: #3661dd;
  }
}
.purchase-list-value {
  &:hover {
    text-decoration: underline;
  }
}
</style>
