import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client';

export const usePriceChangeRule = (variables) => {
  const { result, loading, onError, refetch } = useQuery(PRICE_CHANGE_RULE, variables);

  const priceChangeRule = computed(() => {
    if (result.value) {
      const { nodes, totalCount } = result.value?.priceChangeRules;
      if (totalCount === 0) return null;
      if (totalCount === 1) return nodes[0];
    }
    return null;
  });

  return {
    priceChangeRule,
    loading,
    refetch,
    onError,
  };
};

const PRICE_CHANGE_RULE = gql`
  query priceChangeRule($businessId: ID, $productId: ID) {
    priceChangeRules(businessId: $businessId, productId: $productId) {
      nodes {
        businessId
        productId
        netPrice
        trend
        id
        active
        createdAt
      }
      totalCount
    }
  }
`;
