<template>
  <div class="alerts-drawer-content">
    <div class="d-flex" :style="{ gap: '0.75rem' }">
      <Button
        v-for="(headerAction, index) in headerActions"
        :key="`headerAction-${index}`"
        type="secondary"
        :style="{ padding: '0 0.5rem', height: '2rem' }"
        @click="headerAction.action"
      >
        <div class="d-flex gap-1 align-items-center">
          <component :is="headerAction.icon" :size="20" />
          <span>{{ headerAction.label }}</span>
        </div>
      </Button>
    </div>
    <div class="alerts-header-actions">
      <div class="handled">
        <div class="handled-label">
          <AlertsHandleIcon class="handled-label-icon" />
          <span>{{ $t('alert.drawer.quickActions.handled.label') }}</span>
        </div>
        <div class="handled-action">
          <el-dropdown trigger="click" @command="onHandledChange">
            <el-tooltip v-if="handledByUser" placement="top" :content="getDropdownText()">
              <div class="dropdown-label-avatar">
                <UserAvatar
                  v-if="handledByUser"
                  :user="handledByUser.profile"
                  :token="token"
                  :show-user-tooltip="false"
                  class="handled-user-avatar"
                />
                <span
                  class="handled-by-user"
                  :class="{
                    enabled: handledByUser,
                  }"
                  >{{ getDropdownText(true) }}</span
                >
                <ChevronDownIcon />
              </div>
            </el-tooltip>
            <div v-else class="dropdown-label-avatar">
              <span>{{ getDropdownText(true) }}</span>
              <ChevronDownIcon />
            </div>
            <el-dropdown-menu>
              <el-dropdown-item command="false" :class="{ active: !handledByUser }">
                {{ $t(`alert.drawer.quickActions.handled.values.false`) }}
              </el-dropdown-item>
              <el-dropdown-item command="true" :class="{ active: handledByUser }">{{
                $t(`alert.drawer.quickActions.handled.values.true`)
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <slot name="quick-actions"></slot>
      <div class="header-quick-actions-divider" />
    </div>
  </div>
</template>
<script>
import { computed, getCurrentInstance } from 'vue';

import { useUser } from '@/modules/auth';
import { Button } from '@/modules/core';
import { AlertsHandleIcon, ChevronDownIcon } from '@/assets/icons';
import UserAvatar from '@/modules/orderProcess/components/UserAvatar/UserAvatar.vue';

export default {
  name: 'AlertQuickActions',
  components: { Button, UserAvatar, ChevronDownIcon, AlertsHandleIcon },
  props: { alert: { type: Object, required: true }, headerActions: { type: Array, default: () => [] } },
  emits: ['on-handled-status-change'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const { token } = useUser();

    const onHandledChange = (command) => {
      const isHandled = command === 'true';

      if (props.alert?.handled === isHandled) return;

      emit('on-handled-status-change', isHandled);
    };

    const formatDate = (ms) =>
      new Date(ms).toLocaleDateString(root.$i18n.locale, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });

    const getDropdownText = (isLabel = false) => {
      if (props.alert?.handled) {
        const date = formatDate(props.alert.handledAt);
        const userProfile = props.alert.handledByUser.profile;
        const fullName = `${userProfile.firstName} ${userProfile.lastName}`;

        return root.$t(`alert.drawer.quickActions.handled.${isLabel ? 'byUser' : 'byUserTooltip'}`, {
          name: fullName,
          date: date,
        });
      } else {
        return root.$t(`alert.drawer.quickActions.handled.values.false`);
      }
    };

    const handledByUser = computed(() => props.alert?.handledByUser);

    return { token, handledByUser, onHandledChange, getDropdownText };
  },
};
</script>
<style lang="scss" scoped>
.header-quick-actions-divider {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #e3e4e6;
}

.alerts-drawer-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 20px !important;

  .alerts-header-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.handled {
  display: flex;
  width: 100%;
  font-size: 0.875rem;
  height: 21px;

  .handled-label {
    display: flex;
    align-items: center;
    width: 46%;

    .handled-label-icon {
      [dir='ltr'] & {
        margin-right: 10px;
      }
      [dir='rtl'] & {
        margin-left: 10px;
      }
    }
  }

  .handled-action {
    width: 50%;

    .dropdown-label-avatar {
      display: flex;
      align-items: center;
    }

    .handled-user-avatar {
      [dir='ltr'] & {
        margin-right: 8px;
      }
      [dir='rtl'] & {
        margin-left: 8px;
      }
    }

    .handled-by-user {
      color: #1f284d;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.active {
  color: #3661dd;
  font-weight: 700;
}
</style>
