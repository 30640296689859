import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client';

export const useAnnualImpactForPriceChangeAlert = (variables) => {
  const { result, loading, onError, refetch } = useQuery(
    ANNUAL_IMPACT_FOR_PRICE_ALERT,
    computed(() => ({
      ...variables.value,
      aggregateBy: 'product',
      monthlyAverage: true,
    })),
    () => ({
      fetchPolicy: 'cache-first',
      enabled: !!variables.value.productIds?.length,
    })
  );

  const orderItemAggregation = computed(() => result.value?.orderItemAggregationNew?.nodes);

  return {
    orderItemAggregation,
    loading,
    refetch,
    onError,
  };
};

const ANNUAL_IMPACT_FOR_PRICE_ALERT = gql`
  query annualImpactForPriceChangeAlert(
    $tenantId: ID
    $aggregateBy: String
    $monthlyAverage: Boolean
    $productIds: [ID]
  ) {
    orderItemAggregationNew(
      tenantId: $tenantId
      aggregateBy: $aggregateBy
      monthlyAverage: $monthlyAverage
      productIds: $productIds
    ) {
      nodes {
        productId
        monthlyAverageTotalQuantity
      }
    }
  }
`;
