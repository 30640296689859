import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client';

export const useOrderForPriceChangeAlert = (orderId) => {
  const { result, loading, onError, refetch } = useQuery(
    ORDER_FOR_PRICE_CHANGE_ALERT,
    () => ({ id: orderId.value }),
    () => ({
      enabled: !!(orderId && orderId?.value),
      fetchPolicy: 'cache-first',
    })
  );

  const order = computed(() => result.value?.order);

  return {
    order,
    loading,
    refetch,
    onError,
  };
};

const ORDER_FOR_PRICE_CHANGE_ALERT = gql`
  query orderForPriceChangeAlert($id: ID!) {
    order(id: $id) {
      products {
        productId
        quantity
      }
      eventReferences {
        document {
          id
          documentNumber
          filePathUrl
        }
      }
    }
  }
`;
